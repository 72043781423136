const infuraBaseUrl = {
  matic: "polygon-mainnet",
  matictestnet: "polygon-mumbai",
};

export const supportedImgs = ['image/gif', 'image/jpeg', 'image/png', 'image/psd', 'image/bmp', 'image/tiff', 'image/jp2', 'image/iff', 'image/vnd.wap.wbmp', 'image/xbm'];
export const supportedVideos = ['video/mp4', 'video/mov', 'video/mpg', 'video/flv', 'video/ogg', 'video/webm'];

export const usdSymbol = "$";
export const ethSymbol = "MATIC ";
export const bidMaxVal = 0.25;
export const unknownNetwork = 'matictestnet';
export const network = localStorage.getItem('network') != null ? localStorage.getItem('network') : unknownNetwork;
export const infuraUrl = `https://${infuraBaseUrl[network] ? infuraBaseUrl[network] : network
  }.infura.io/v3/`;
export const infuraId = "55965aed0f8e4bd08a07ceb71d71e28d";//"88904a7034a74f0fa8c2de15a4928e34";
export const etheriumProvider = window.ethereum ? window.ethereum : 'https://matic-mumbai.chainstacklabs.com/';
export const ipfsUrl = "https://ipfs.io/ipfs";
export const ipfsInfuraUrl = "https://ipfs.infura.io:5001";

export const supportedNetworkVersions = ["80001"] //  '80001', '137' => Kovan, Matic TEST, Matic Mainnet 
export const supportedNetworks = "Polygon Matic Testnet" // '80001', '137' => Kovan, Matic TEST, Matic Mainnet 
export const networkName = network;

const networkUrls = {
  matic: "https://polygonscan.com/",
  matictestnet: "https://mumbai.polygonscan.com/"
};
/**EXPLORERS END POINTS */
export const explorerNetworkURL = networkUrls[network];

const contractAdd = {
  matic: "0xF52Ace7ec78237eA98F33FE05a82f0B1A140D900",
  matictestnet: "0xDa12865F348c53577A8c91e9e262D7Cc58843d12"
};
// main net matic address= 
export const contractAddress = contractAdd[network]
export const UPLOAD_FILE_SIZE=5
export const RELATED_PRODUCT_LIMIT=5