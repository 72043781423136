import { Redirect } from 'react-router-dom'
import { supportedNetworkVersions, supportedNetworks } from './../../constant/constant'
import { SwitchNetworkMessage } from '../../utils/message'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import IntroVideo from './IntroVideo'

const $ = window.jQuery

function GettingStarted(props) {

  let connected = Boolean(localStorage.getItem('connectd'))
  let token = Boolean(localStorage.token)
  const [redirect, setRedirect] = useState(false)
  const [connect, setConnect] = useState(false)

  useEffect(() => {
    if (connected) {
      setConnect(true)
    } else if (!connected) {
      setConnect(false)
    }
  }, [props.walletConnection])

  useEffect(() => {
    if (connected) {
      setConnect(true)
    } else if (!connected) {
      setConnect(false)
    }
  }, [token, props.token])

  const openTab = () => {
    if (!connected || connected === null || supportedNetworkVersions.indexOf(window.ethereum.networkVersion) === -1) {
      SwitchNetworkMessage('error', 'Sorry', "Please Connect to supported network (" + supportedNetworks + ") first or connect wallet.")
      return;
    } else {
      setRedirect(true)
    }
  }

  const openSignUpTab = (param) => {
    $(`#${param}`).tab('show');
  }
  const videoURL = props.homeVideo;

  if (redirect) {
    return <Redirect to='/create-item' />
  } return (
    <section className="getting-started">
      <div className="container">
        <div className="row">
          <div className="col-md-7 d-flex align-items-center">
            <div className="content">
              <h2>Getting Started with NFTs</h2>
              <p><strong>Artists! Stop f*cking around &amp; start making money.</strong></p><p><strong>Wait, what the heck are these NFTs again?</strong> Grimes made over<strong> 7 million</strong> releasing NFT music, The Weeknd over <strong>2 million</strong>. Beeple has made over 100 million just selling his NFTs. <em>Are you paying attention yet?</em> <strong><em>If not, let someone else make all your money doing what they love. </em></strong></p><p><a href="https://nexus.art" target="_blank" rel="noopener"><strong>This is the paradigm shift. We are Nexus.Art</strong></a></p><p>We make the buying and selling of NFT’s easy with <strong>NO FEES</strong> to mint your art.<strong> Our NFT platform is set up for creators, not just crypto-punks</strong>. Just go to our website at Nexus.Art where we walk you through every step of putting your music on the blockchain.</p><p><strong><em>Stop wasting time &amp; get in on the Gold Rush, your fans are waiting!</em></strong></p>	

              <IntroVideo video={videoURL} />

              {token ? <button onClick={connect ? openTab : () => { }} className="btn-default hvr-bounce-in"><span className="icon">
                <img className="img-fluid" src={props.Logo} alt="Nexus Logo" />
              </span>{connect ? "Get Started" : 'No Wallet Connected'}</button> :
                <button className="btn-default hvr-bounce-in" data-toggle='modal' data-target='.bd-example-modal-lg' onClick={() => openSignUpTab('v-pills-signup-tab')} >
                  <span className="icon">
                    <img className="img-fluid" src={props.Logo} alt="Nexus Logo" />
                  </span>Join Now </button>}
            </div>
          </div>
          <div className="col-md-5">
            <div className="image">
              <img src={props.Image} alt="Getting Started with NFTs" className="bounce img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
const mapStateToProps = state => {
  return {
    walletConnection: state.userReducer.walletConnection,
    token: state.userReducer.token,
    homeVideo: state.siteSettingReducer.homeVideo,
  }
}

export default connect(mapStateToProps)(GettingStarted)