import Swal from "sweetalert2";
import { supportedNetworks } from "../constant/constant";

export const Message = (icon, title, text) => {
  Swal.fire({
    icon,
    title,
    text,
    showConfirmButton: true,
    timer: (icon === "error" || icon === "warning") ? 0 : 5000,
  });
};

export const HotMessage = (icon, title, text) => {
  Swal.fire({
    icon,
    title,
    text,
    showConfirmButton: false,
    hideOnOverlayClick: false,
    allowOutsideClick: false,
    closeClick: false,
    helpers: {
      overlay: { closeClick: false },
    },
  });
};

export const messageFormater = (message) => {
  let result = "";
  if (typeof message === "string") {
    result = message;
  } else if (typeof message === "object" && message.length > 0) {
    for (let index = 0; index < message.length; index++) {
      result = result ? result + message[index] : message[index];
    }
  }
  return result;
};
export const ErrorHandler = (error) => {
  if (error && error.response && error.response.status === 401) {
    localStorage.clear();
    this.props.history.push("/");
    window.location.reload(true);
  }
};

const changeNetwork = async () => {
  // const WEB3_CONNECT_CACHED_PROVIDER = localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER');
  // if (WEB3_CONNECT_CACHED_PROVIDER == '"injected"') {
  let ethereum = window.ethereum;
  try {
    ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x13881" }], // 0x89 => matic live
    }).then((_chainId) => {
      if (_chainId == null) {
        localStorage.setItem('connectd', true)
      }
    });

  } catch (switchError) {
    if (switchError.code === 4902) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            { chainId: "0x13881", rpcUrl: "https://rpc-mumbai.maticvigil.com/" },
          ],
        }).then((_chainId) => {
          if (_chainId == null) {
            localStorage.setItem('connectd', true)
          }
        })
      } catch (addError) {
        console.log(addError, "addError");
      }
    } else if (switchError.code === 4001) {
      SwitchNetworkMessage(
        "warning",
        "Sorry",
        "Please connect to supported network (" +
        supportedNetworks +
        ") first or connect wallet."
      );
    }
  }
  // }/
};

export const SwitchNetworkMessage = (icon, title, text) => {
  // const WEB3_CONNECT_CACHED_PROVIDER = localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER');
  let showConfirmButton = false;
  // if (WEB3_CONNECT_CACHED_PROVIDER === '"injected"') {
  showConfirmButton = true;
  // }
  Swal.fire({
    icon,
    title,
    text,
    showConfirmButton: showConfirmButton,
    hideOnOverlayClick: false,
    allowOutsideClick: false,
    confirmButtonText: `Switch to ${process.env.REACT_APP_NETWORK}`,
    closeClick: false,
    // width: "60rem",
    heightAuto: false,
    helpers: {
      overlay: { closeClick: false },
    },
  }).then(() => changeNetwork());
};
