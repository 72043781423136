import { Redirect } from 'react-router-dom'
import { supportedNetworkVersions, supportedNetworks } from './../../constant/constant'
import { SwitchNetworkMessage } from '../../utils/message'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
const $ = window.jQuery
function Ecommerce(props) {

  let connected = Boolean(localStorage.getItem('connectd'))
  let token = Boolean(localStorage.token)
  const [redirect, setRedirect] = useState(false)
  const [connect, setConnect] = useState(false)

  useEffect(() => {
    if (connected) {
      setConnect(true)
    } else if (!connected) {
      setConnect(false)
    }
  }, [props.walletConnection])

  useEffect(() => {
    if (connected) {
      setConnect(true)
    } else if (!connected) {
      setConnect(false)
    }
  }, [token, props.token])

  const openTab = () => {
    if (!connected || connected === null || supportedNetworkVersions.indexOf(window.ethereum.networkVersion) === -1) {
      SwitchNetworkMessage('error', 'Sorry', "Please Connect to supported network (" + supportedNetworks + ") first or connect wallet.")
      return;
    } else {
      setRedirect(true)
    }
  }

  const openSignUpTab = (param) => {
    $(`#${param}`).tab('show');
  }

  if (redirect) {
    return <Redirect to='/create-item' />
  } return (
    <section className="art-commerce main-padding">
      <div className="row">
        <div className="col-md-6 col-md-6 d-flex align-items-center">
          <div className="content">
            <h1>THE NEXUS OF ART &amp; COMMERCE</h1>
            <p>Nexus.Art is here to democratize the process of monetization for musicians & artists, finally making the dream of the independent artist a reality.NFTs truly have the potential to shift the paradigm & change the world. Power to the people! </p>
            <p style={{ marginBottom: '20px' }}><strong>Artists &amp; Musicians! <em>Stop f*cking around</em> &amp; start making <img draggable="false" role="img" className="emoji" alt="💰" src="https://s.w.org/images/core/emoji/13.1.0/svg/1f4b0.svg" width="20" /></strong><strong> from your life’s work!</strong></p>
            <p style={{ marginBottom: '20px' }}><strong>Investors! NFT Sales Surge to Over 10 Billion Dollars in 2021!</strong> </p>
            <h3 style={{ marginBottom: '20px' }}>Join the revolution & claim your piece of history at Nexus.art</h3>
            <div className="d-flex">
              {token ? <button onClick={connect ? openTab : () => { }} className="btn-default hvr-bounce-in"><span className="icon">
                <img className="img-fluid" src={props.Logo} alt="Nexus Logo" />
              </span>{connect ? "Start Selling" : 'No Wallet Connected'}</button> :
                <button className="btn-default hvr-bounce-in" data-toggle='modal' data-target='.bd-example-modal-lg' onClick={() => openSignUpTab('v-pills-signup-tab')} >
                  <span className="icon">
                    <img className="img-fluid" src={props.Logo} alt="Nexus Logo" />
                  </span>Join Now </button>}
              <button className="btn-default hvr-bounce-in ml-2" data-toggle='modal' data-target='.bd-example-modal-lg' onClick={() => openSignUpTab('v-pills-signup-tab')} >
                <span className="icon">
                  <img className="img-fluid" src={props.Logo} alt="Nexus Logo" />
                </span>WHAT ARE NFTs? </button>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="image">
            <img src={props.Image} alt="Art & Commerce" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  )
}
const mapStateToProps = state => {
  return {
    walletConnection: state.userReducer.walletConnection,
    token: state.userReducer.token
  }
}

export default connect(mapStateToProps)(Ecommerce)